import React, { useContext, useRef, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import { Button, Container, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import SelectMultiplo from '../../DevComponents/SelectMultiplo'
import { ContratoInterface, ContratoPesquisaInterface } from '../../ImportBackend/Interfaces/ContratoInterfaces'
import { MotivoCancelamentoContratoTypes, StatusContratoType, StatusContratoTypes } from '../../ImportBackend/types/ConstantesDataTypes'
import InputText from '../../DevComponents/InputText'
import ClsValidacao from '../../Utils/ClsValidacao'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import ComboBox from '../../DevComponents/ComboBox'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import { clsUtils } from 'zlib-utils'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import ClientesCancelarContrato from './ClientesCancelarContrato'
import Condicional from '../../Layout/Condicional'
import { useReactToPrint } from 'react-to-print'
import ClsExportDataTableCSV from '../../Utils/ClsExportDataTableCSV'
import ContratosEditarStatus from './ContratosEditarStatus'

enum StatusFormEnum {
  PESQUISANDO = 1,
  EXCLUINDO_CONTRATO = 2,
  EDITANDO_EXCLUSAO = 3,
  EDITAR_STATUS = 4
}

export default function Contratos () {

  const componentRef = useRef( null )

  const handlePrint = useReactToPrint( {
    content: () => componentRef.current,
  } )

  const handleDownload = () => {

    ( new ClsExportDataTableCSV() ).exportCSV( 'Contratos', rsPesquisa, CabecalhoRelatorio )

  }

  const [status, setStatus] = useState<StatusFormEnum>( StatusFormEnum.PESQUISANDO )

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  const ResetPesquisa: ContratoPesquisaInterface = {
    motivoCancelamento: '',
    inicioCancelamento: '',
    terminoCancelamento: '',
    inicioEmissao: '',
    terminoEmissao: '',
    status: [],
    unidades: [],
    idProduto: 0,
    nomeCliente: '',
    numeroContrato: 0
  }

  const [pesquisa, setPesquisa] = useState<ContratoPesquisaInterface>( ResetPesquisa )
  const [errosPesquisa, setErrosPesquisa] = useState( {} )

  const [rsPesquisa, setRsPesquisa] = useState<Array<ContratoInterface>>( [] )

  const [dados, setDados] = useState<ContratoInterface>( {} as any )

  const btFechar = () => {
    navigate( '/' )
  }

  const validarPesquisa = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'inicioCancelamento', pesquisa, erros, retorno, true )
    retorno = clsValidacao.eData( 'terminoCancelamento', pesquisa, erros, retorno, true )
    retorno = clsValidacao.eData( 'inicioEmissao', pesquisa, erros, retorno, true )
    retorno = clsValidacao.eData( 'terminoEmissao', pesquisa, erros, retorno, true )

    if ( retorno
      && pesquisa.inicioCancelamento
      && pesquisa.inicioCancelamento.length > 0
      && pesquisa.terminoCancelamento
      && pesquisa.terminoCancelamento.length > 0
      && pesquisa.inicioCancelamento > pesquisa.terminoCancelamento
    ) {
      erros.inicioCancelamento = 'Período Inválido!'
      erros.terminoCancelamento = 'Período Inválido!'
      retorno = false
    }

    if ( retorno
      && pesquisa.inicioEmissao
      && pesquisa.inicioEmissao.length > 0
      && pesquisa.terminoEmissao
      && pesquisa.terminoEmissao.length > 0
      && pesquisa.inicioEmissao > pesquisa.terminoEmissao
    ) {
      erros.inicioEmissao = 'Período Inválido!'
      erros.terminoEmissao = 'Período Inválido!'
      retorno = false
    }

    setErrosPesquisa( erros )

    return retorno
  }

  const pesquisar = () => {

    if ( validarPesquisa() ) {

      const query: string = `
        getContratos(dados: ${clsUtils.ConverterEmGql( pesquisa )}) {
          idContrato
          idCliente
          idUnidade
          idProduto
          valorTotal
          primeiroVencimento
          parcelas
          vrDesconto
          perJuros
          status
          motivoCancelamento
          vrExecutado
          dataCancelamento
          cpfResponsavelPreenchimento
          nomeResponsavelPreenchimento
          chaveUUID
          observacao
          createdAt
          updatedAt
          Unidade {
            descricao
          }
          Produto {
            descricao
          }
          Cliente {
            nome
            idCliente
          }
        }
      `

      clsApi.query<Array<ContratoInterface>>( query, 'getContratos', 'Pesquisando Contratos...', contexto, abortController ).then( ( rs ) => {
        setRsPesquisa( rs )
      } )

      return () => {

        abortController.abort()
      }
    }
  }

  const CabecalhoRelatorio: Array<DataTableCabecalhoInterface> = [

    /*
    SELECT  ct.idContrato
           ,ct.idCliente
           ,cl.nome      AS nome
           ,ct.idUnidade
           ,un.descricao AS descricaoUnidade
           ,ct.idProduto
           ,pr.descricao AS descricaoProduto
           ,re.descricao AS descricaoRegional
           ,ct.valorTotal
           ,ct.primeiroVencimento
           ,ct.parcelas
           ,ct.vrDesconto
           ,ct.perJuros
           ,ct.STATUS
           ,ct.cpfResponsavelPreenchimento
           ,ct.nomeResponsavelPreenchimento
           ,ct.parcelasCora->"$.document_url"
    
    
    
    */
    {
      campo: 'idContrato',
      cabecalho: 'Contrato',
      alinhamento: 'left',
      format: ( v: number ) => '000000'.concat( v.toString() ).slice( -6 )
    },
    {
      campo: 'Cliente',
      cabecalho: 'Cliente',
      alinhamento: 'left',
      format: ( v ) => v.nome
    },
    {
      campo: 'Unidade',
      cabecalho: 'Unidade',
      alinhamento: 'left',
      format: ( v ) => v.descricao
    },
    {
      campo: 'createdAt',
      cabecalho: 'Emissão',
      alinhamento: 'left',
      format: ( v: string ) => clsFormatacao.dataISOtoUser( v )
    },
    {
      campo: 'Produto',
      cabecalho: 'Produto',
      alinhamento: 'left',
      format: ( v ) => v.descricao
    },
    {
      campo: 'valorTotal',
      cabecalho: '$ Total',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    },
    {
      campo: 'vrExecutado',
      cabecalho: '$ Executado',
      alinhamento: 'right',
      format: ( v: string ) => clsFormatacao.currency( parseFloat( v ) )
    },
    {
      campo: 'observacao',
      cabecalho: 'Observação',
      alinhamento: 'left'
    },
    {
      campo: 'status',
      cabecalho: 'Status',
      alinhamento: 'left',
      format: ( rs: StatusContratoType ) => StatusContratoTypes.find( ( v ) => v.idStatus === rs )?.descricao
    }

  ]

  const btCancelarContrato = ( rs: ContratoInterface ) => {
    setDados( rs )
    setStatus( StatusFormEnum.EXCLUINDO_CONTRATO )
  }

  const btEditarStatus = ( rs: ContratoInterface ) => {
    setDados( rs )
    setStatus( StatusFormEnum.EDITAR_STATUS )
  }

  const onCancelarExclusaoEdicao = () => {
    setStatus( StatusFormEnum.PESQUISANDO )
    pesquisar()
  }
  /*
  const pesquisar = () => {
  
    
  }      
  
  /*
    useEffect( () => {
  
      pesquisar()
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )
  
  */

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }} ref={componentRef}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Condicional condicao={status === StatusFormEnum.PESQUISANDO}>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography component="h5" variant="h5" align="left">
                  Contratos
                  <Typography variant="body2" gutterBottom>
                    Pesquisa de Contratos
                  </Typography>
                </Typography>

                <IconButton onClick={() => btFechar()}>
                  <CloseIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} sm={3} md={2}>

                <InputText
                  dados={pesquisa}
                  field='numeroContrato'
                  label='Contrato'
                  setState={setPesquisa}
                  erros={errosPesquisa}
                  tipo='number'
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={12} sm={9} md={5} sx={{ pl: { sm: 1 } }} >

                <InputText
                  dados={pesquisa}
                  field='nomeCliente'
                  label='Nome do Cliente'
                  setState={setPesquisa}
                  erros={errosPesquisa}
                />

              </Grid>

              <Grid item xs={12} md={5} sx={{ pl: { md: 1 } }} >

                <SelectMultiplo
                  dados={pesquisa}
                  field='unidades'
                  fieldIdSelect='idUnidade'
                  fieldSelect='descricao'
                  label='Unidades'
                  setState={setPesquisa}
                  pesquisaInicial={{
                    mensagem: 'Pesquisando Unidades',
                    tipo: 'Query',
                    pesquisa: 'getUsuarioUnidadesPermitidasPorAcesso {idUnidade, descricao}',
                    objRetorno: 'getUsuarioUnidadesPermitidasPorAcesso'
                  }
                  }
                />

              </Grid>

              <Grid item xs={12} sm={6} md={3}>

                <InputText
                  dados={pesquisa}
                  field='inicioEmissao'
                  label='Emitido a partir de'
                  setState={setPesquisa}
                  erros={errosPesquisa}
                  tipo='date'
                  mask='00/00/0000'
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={12} sm={6} md={3} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={pesquisa}
                  field='terminoEmissao'
                  label='Emitido até'
                  setState={setPesquisa}
                  erros={errosPesquisa}
                  tipo='date'
                  mask='00/00/0000'
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={12} sm={6} md={3} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={pesquisa}
                  field='inicioCancelamento'
                  label='Cancelado a partir de'
                  setState={setPesquisa}
                  erros={errosPesquisa}
                  tipo='date'
                  mask='00/00/0000'
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={12} sm={6} md={3} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={pesquisa}
                  field='terminoCancelamento'
                  label='Cancelado até'
                  setState={setPesquisa}
                  erros={errosPesquisa}
                  tipo='date'
                  mask='00/00/0000'
                  textAlign="right"
                />

              </Grid>

              <Grid item xs={12} md={6} sx={{ pl: { md: 1 } }}>

                <SelectMultiplo
                  dados={pesquisa}
                  field='status'
                  fieldIdSelect='idStatus'
                  fieldSelect='descricao'
                  label='Status'
                  setState={setPesquisa}
                  rsSelect={StatusContratoTypes}
                  erros={errosPesquisa}
                />

              </Grid>

              <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>

                <ComboBox
                  dados={pesquisa}
                  label='Motivo'
                  setState={setPesquisa}
                  opcoes={MotivoCancelamentoContratoTypes}
                  campoDescricao='descricao'
                  campoID='idMotivo'
                  field='motivoCancelamento'
                  valorPadraoCampoEmBranco={''}
                  erros={errosPesquisa}
                />

              </Grid>

              <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>

                <PesquisarTabela<any>
                  setState={setPesquisa}
                  field='idProduto'
                  fieldSet='idProduto'
                  label='Produto'
                  dados={pesquisa}
                  campoQueryPesquisaID='idProduto'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idProduto, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getProdutos'
                  nomeQueryPesquisaID='getProdutoPorId'
                  mensagemPesquisa='Procurando Produto...'
                  erros={errosPesquisa}
                  pesquisarTudoAoIniciar
                  valorAtribuirLimpar={0}
                />

              </Grid>

              <Grid className="noprint" item xs={12} sx={{ textAlign: 'right', mt: 3 }}>
                <Button variant='contained' onClick={() => btFechar()}>Cancelar</Button>

                <Button variant='contained' onClick={() => pesquisar()} sx={{ ml: 1 }}>Pesquisar</Button>
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable
                  dados={rsPesquisa}
                  cabecalho={CabecalhoRelatorio}

                  acoes={[
                    {
                      icone: 'clear',
                      toolTip: 'Cancelar',
                      onAcionador: btCancelarContrato,
                      onDisabled: ( rs: any ) => rs.status === StatusContratoType.CANCELADO || rs.status === StatusContratoType.CONCLUIDO
                    },
                    {
                      icone: 'edit',
                      toolTip: 'Editar Cancelamento',
                      onAcionador: btCancelarContrato,
                      onDisabled: ( rs: any ) => rs.status !== StatusContratoType.CANCELADO
                    },
                    {
                      icone: 'build',
                      toolTip: 'Editar Status',
                      onAcionador: btEditarStatus,
                      onDisabled: ( rs: any ) => rs.status === StatusContratoType.CADASTRADO
                    }
                  ]}
                />
              </Grid>

              <Grid className="noprint" item xs={12} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: 'right' }}>
                <Button variant='contained' onClick={() => handlePrint()}>Imprimir</Button>
                <Button variant='contained' onClick={() => handleDownload()} sx={{ ml: 1 }}>Download</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={status === StatusFormEnum.EXCLUINDO_CONTRATO || status === StatusFormEnum.EDITAR_STATUS}>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography component="h5" variant="h5" align="left">
                  {status === StatusFormEnum.EXCLUINDO_CONTRATO ? 'Cancelamento de Contrato' : 'Edição de Status'}
                  <Typography variant="body2" gutterBottom>
                    {status === StatusFormEnum.EXCLUINDO_CONTRATO ? 'Cancelamento de Contratos Emitidos' : 'Edição de Status de Contratos Emitidos'}

                  </Typography>
                </Typography>

                <IconButton onClick={() => btFechar()}>
                  <CloseIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} md={6} >
                <TextField fullWidth label="Cliente" defaultValue={dados.idContrato ? ( dados as any ).Cliente.nome : ''} disabled />
              </Grid>

              <Grid item xs={4} md={2} sx={{ pl: { md: 1 } }}>
                <TextField fullWidth label="Contrato" defaultValue={dados.idContrato || 0} disabled />
              </Grid>

              <Grid item xs={4} md={2} sx={{ pl: 1 }}>
                <TextField fullWidth label="Valor Total" defaultValue={clsFormatacao.currency( dados.valorTotal || 0 )} disabled />
              </Grid>

              <Grid item xs={4} md={2} sx={{ pl: 1 }}>
                <TextField fullWidth label="Parcelas" defaultValue={dados.parcelas || 0} disabled />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <Condicional condicao={status === StatusFormEnum.EXCLUINDO_CONTRATO}>
                  <ClientesCancelarContrato idContrato={dados.idContrato as number} onCancelar={() => onCancelarExclusaoEdicao()} />
                </Condicional>

                <Condicional condicao={status === StatusFormEnum.EDITAR_STATUS}>
                  <ContratosEditarStatus rsContrato={dados as any} onCancelar={() => onCancelarExclusaoEdicao()} />
                </Condicional>
              </Grid>
            </Condicional>


          </Grid>

        </Paper>

      </Container>

    </>
  )

}