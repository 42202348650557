import React, { useContext, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import InputText from '../../DevComponents/InputText'
import { ContratoCancelamentoInterface, ContratoEdicaoStatusInterface, ContratoInterface } from '../../ImportBackend/Interfaces/ContratoInterfaces'
import ComboBox from '../../DevComponents/ComboBox'
import { StatusContratoEdicaoTypes, StatusContratoType } from '../../ImportBackend/types/ConstantesDataTypes'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'
import { clsUtils } from 'zlib-utils'
import ClsValidacao from '../../Utils/ClsValidacao'

interface PropsInterface {
  onCancelar: () => void
  rsContrato: ContratoInterface & ContratoCancelamentoInterface
}

export default function ContratosEditarStatus ( { onCancelar, rsContrato }: PropsInterface ) {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const ResetDados: ContratoEdicaoStatusInterface = {
    observacao: rsContrato.observacao,
    idContrato: rsContrato.idContrato as number,
    vrExecutado: rsContrato.vrExecutado,
    status: rsContrato.status || StatusContratoType.EMITIDO
  }

  const [dados, setDados] = useState<ContratoEdicaoStatusInterface>( ResetDados )

  const clsApi = new BackEndAPI()
  const clsValidacao = new ClsValidacao()
  const [erros, setErros] = useState( {} )

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    retorno = clsValidacao.naoVazio( 'status', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const onConfirmarAlteracaoStatusContrato = () => {

    if ( validarDados() ) {

      const mutation: string = `
        alterarStatusContrato(dados: ${clsUtils.ConverterEmGql( dados )} )
          {
            ok
            mensagem
          }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'alterarStatusContrato', 'Alterando Status do Contrato', contexto ).then( rs => {
        if ( !rs.ok ) {
          setMensagemState( {
            ...mensagemState,
            titulo: 'Erro ao Alterar Status!!!',
            exibir: true,
            mensagem: 'Status Não Alterado!',
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )
        } else {
          onCancelar()
        }
      } )

    }

  }

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Alteração do Status
                <Typography variant="body2" gutterBottom>
                  Edição do Status do Contrato
                </Typography>
              </Typography>

              <IconButton onClick={() => onCancelar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} md={2}>

              <ComboBox
                campoDescricao='descricao'
                campoID='idStatus'
                dados={dados}
                field='status'
                setState={setDados}
                label='Status do Contrato'
                opcoes={StatusContratoEdicaoTypes}
                erros={erros}
              />

            </Grid>


            <Grid item xs={12} md={4} sx={{ pl: { md: 1 } }}>

              <InputText
                dados={dados}
                field='observacao'
                label='Observação'
                setState={setDados}
                maxLength={255}
              />

            </Grid>

            <Grid item xs={12} md={2} sx={{ pl: { md: 1 } }}>

              <InputText
                dados={dados}
                field='vrExecutado'
                label='Vr. Executado'
                setState={setDados}
                tipo='currency'
                textAlign="right"
              />

            </Grid>

            <Grid item xs={12} md={2} sx={{ mt: 2, textAlign: 'right' }}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ mt: 2, py: 1 }} onClick={() => onConfirmarAlteracaoStatusContrato()}>Confirmar</Button>
            </Grid>

            <Grid item xs={12} md={2} sx={{ mt: 2, textAlign: 'right' }}>
              <Button variant='contained' startIcon={<CloseIcon />} sx={{ mt: 2, py: 1 }} onClick={() => onCancelar()}>Fechar</Button>
            </Grid>


          </Grid>
        </Paper>
        <ExibirJSONDev exibir={EMDESENVOLVIMENTO && true} oque={['Dados', dados]} />
      </Container>

    </>
  )

}